@import './../../styles/helpers';

.bg_1 {
    background: linear-gradient(#FFF1EB, #EEE5E0);
}

.accent_1 {
    color: #D26145;
    --bs-table-bg: #D26145 !important;
}

.bg_2 {
    background: linear-gradient(#c68e8e, #ee5656);
}

.accent_2 {
    color: rgb(117, 34, 34);
    --bs-table-bg: rgb(117, 34, 34) !important;
}

.bg_3 {
    background: linear-gradient(#E3C297, #D1C9BC);
}

.accent_3 {
    color: rgb(95, 79, 45);
    --bs-table-bg: rgb(95, 79, 45) !important;
}

.bg_4 {
    background: linear-gradient(#83B5C7, #89BBC3);
}

// .accent_4 {
//     color: rgb(56, 121, 154);
//     --bs-table-bg: rgb(56, 121, 154);
// }