$form_text: rgba(0, 0, 0, 0.51);

//breakpoint
$desctop: 1400px;
$laptop: 1200px;
$laptop-min: 992px;
$mobile: 768px;
$mobile-min: 576px;
$mobile-extra-min: 360px;

//fonts
@mixin font ($weight, $text-align, $family, $font-size, $line-height){
  font-family: $family;
  font-weight: $weight;
  font-size: $font-size;
  line-height: $line-height;
  text-align: $text-align;
}


@mixin desc {
  @media screen and (min-width: $desctop) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: $laptop) {
    @content;
  }
}

@mixin laptop-min {
  @media screen and (max-width: $laptop-min) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $mobile) {
    @content;
  }
}

@mixin mobile-min {
  @media screen and (max-width: $mobile-min) {
    @content;
  }
}

@mixin mobile-extra-min {
  @media screen and (max-width: $mobile-extra-min) {
    @content;
  }
}