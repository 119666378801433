@import './../../styles/helpers';

.contents_wrapper {
    width: 100%;
    max-width: 700px;
    align-items: center;
}

.continueBtn {
    width: 100%;
    max-width: 200px;
    padding: 1.25rem 0!important;
    font-size: 1rem;
}

.description {
    width: 100%;
    margin-bottom: 0.625rem;
    @include font(400, center, 'Poppins', 1.5rem, 2.1rem);

    @include laptop-min {
        @include font(400, center, 'Poppins', 1.125rem, 1.57rem);
    }
}

.image {
    width: 100%;
    aspect-ratio: 2/1;
}