@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './../../../../styles/helpers';

.recommendations {
  flex-basis: auto;
  max-height: 286px;
}

.cardPlace {
  width: 30%;

  @include laptop {
    width: 45%;
  }
  @include mobile-min {
    width: 100%;
  }
}

.cardPlaceTitle {
  word-break: break-word;
  @include font(600, start, 'Poppins', 1.375rem, 1.65rem);
}

.cardPlaceDetails {
  color: var(--grey-12);
  @include font(400, start, 'Poppins', 0.625rem, 0.75rem);

  @include laptop-min {
    font-size: 0.813rem;
  }

  a {
    text-decoration: underline;
  }

  & > a:first-child {
    margin-bottom: 5px;
  }
}