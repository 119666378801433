@import './../../styles/helpers';

.footer {
    color: var(--grey-2);
    @include font(400, center, 'Poppins', 0.875rem, 1.5rem);
}

//mobile layout
.mobileLayoutBox {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
  //  border: 2px solid red;
  //  padding-bottom: 101px;
    background: var(--grey-5);
    //background:
    //        url('../../assets/Revised_NavBackground.svg') center / cover,
    //        lightgray;
}

.mobile_main {
    display: flex;
    flex-grow: 1;
  //  border: 2px solid blue;
    overflow: hidden;
    text-align: center;
}

/////

.layoutBox {
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
}

.main {
    display: flex;
    flex-grow: 1;
    border-radius: 40px 40px 0px 0px;
   // background: rgba(238, 240, 246, 1);
    background: var(--grey-5);
    overflow: hidden;
    text-align: center;
}

.mainContents {
    padding: 0px 40px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    /*margin:auto;*/

    @include laptop() {
        padding: 0 10px !important;
    }
}

//@media (max-width:670px) {
//    .mainContents {
//        padding: 0 10px !important;
//    }
//}

.flexContainer {
    display: inline-flex;
    flex-basis: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.row {
    display: flex;
    flex-basis: 0;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.col {
    display: flex;
    flex-basis: 1;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
}

/*button*/
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid var(--black-bg);
    border-radius: 230px;
    background: var(--bg-color);
    padding: 8px 16px;
    overflow: hidden;
    color: var(--text-color);
    @include font(700, center, 'Poppins', 0.75rem, 1.5rem);
    text-overflow: ellipsis;

    @media screen and (max-width:1300px) {
        padding: 6px 10px !important;
        font-size: 10px;
        font-weight: 400;
    }
}

//modal
.dialog {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 1003;
}

.panel {
    position: relative;
    border-radius: 32px;
    background: var(--white-bg, #fff);
    padding: 2.5rem 6.25rem;
    max-height: 95%;
    height: auto;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 0;
    }

    @include mobile {
        padding: 2rem;
    }

    @include mobile-min {
        padding: 2rem 1rem;
    }
}

.title {
    color: var(--black-bg, #161a21);
    @include font(700, center, 'Poppins', 2.625rem, 3.675rem);

    @include mobile-min {
        @include font(700, center, 'Poppins', 1.5rem, 2rem);
    }
}

.closeBtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    padding: 0 !important;
    border: none;
    background: transparent;
}