@import './../../../../styles/helpers';

.h2 {
  @include font(600, start, 'Poppins', 1.5rem, 2rem);
  //@include mobile-min {
  //  font-size: 1.7rem;
  //  line-height: 1.9rem;
  //}
}

.h3 {
  @include font(500, start, 'Poppins', 1.4rem, 1.6rem);
  //@include mobile-min {
  //  font-size: 1.6rem;
  //  line-height: 1.8rem;
  //}
}

.mobile_item {
  background-color: white;
  border-radius: 25px;
}