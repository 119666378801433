@import './../../../../styles/helpers';

.small_card {
  &_body {
    h1 {
      font-size: 4rem;
      display: inline;
    }

    p {
      display: inline;
      margin-left: .3rem;
    }

    img {
      display: block;
      max-width: 120px;
      width: 60%;
      margin-left: auto;
      @include mobile-min {
        max-width: 40%;
        width: 40%;
      }
    }
  }
}


.big_display_block_wrapper {
  width: 50%;
  @include laptop {
    width: 100%!important;
  }
}

// Headers Start
.bigHeader {
  position: absolute;
  height: 350px;
  border-radius: 32px;
  background-color: var(--grey-9);
}

@keyframes header_lg_to_sm {
  from {
    width: 100%;
    left: 0;
    right: 0;
  }

  to {
    width: 75%;
    left: 12.5%;
    right: 12.5%;
  }
}

@keyframes header_sm_to_lg {
  from {
    width: 75%;
    left: 12.5%;
    right: 12.5%;
  }

  to {
    width: 100%;
    left: 0;
    right: 0;
  }
}

.bigHeader_sm {
  animation: header_lg_to_sm 0.5s ease forwards;
}

.bigHeader_lg {
  animation: header_sm_to_lg 0.5s ease forwards;
}

.midHeader {
  position: absolute;
  height: 350px;
  border-radius: 32px;
  top: 30px;
  right: 5%;
  background-color: rgba(255, 255, 255, 0.699);
  left: 5%;
}

@keyframes midheader_off_to_on {
  from {
    width: 100%;
    top: 70px;
    left: 0;
    right: 0;
  }

  to {
    width: 90%;
    top: 30px;
    left: 5%;
    right: 5%;
  }
}

.midHeader_on {
  animation: midheader_off_to_on 0.5s ease forwards;
}

// Headers End

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  max-height: 670px;
  width: 335px;
  flex-shrink: 0;

  @include mobile-min {
    max-width: 335px;
    width: 100%;
  }
}
