@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '../../styles/helpers';

.chatMessages {
  flex-grow: 1;
  overflow-y: auto;
  //min-height: 30vh;
  //max-height: 400px;
  height: 400px;
  margin-bottom: 2rem;
  //white-space: break-spaces;

  p {
    margin-bottom: 15px!important;
  }

  p:only-child,
  p:last-child {
    margin: 0!important;
  }

}

.messageBubble {
  word-break: break-all;
  max-width: 70%;
  background-color: var(--grey-5);
}

.messageBubble {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profileImage {
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

@keyframes chatLoc1 {
  from {
    bottom: 100px;
  }

  to {
    bottom: 10px;
  }
}

.chat1 {
  width: 99%;
  animation: chatLoc1 0.5s ease forwards;
}

.no_split_text {
  word-break: keep-all;
  overflow-wrap: break-word;
}