@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './../../../../styles/helpers';

//additional color for 'price button'
.add_color {
  color: var(--black);
}

.chatBox {
  border-radius: 32px;

  .chatOptions {
    overflow-y: auto;
    //min-height: 30vh;
    //max-height: 400px;
    height: 400px;
    margin-bottom: 2rem;
    white-space: pre-wrap;
    p {
      text-align: left;
      margin-top: .8rem;
    }
  }
}

.chatBoxWithBg {
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--grey-5);
    width: 200px;
    height: 68px;
    border-radius: 0 20px 0 0;

    @include mobile-extra-min {
      width: 150px;
    }
  }
}

//this two classes for animation of rounded corners
.chatBoxAnimation {
  width: 100%;
  flex-grow: 1;
  position: relative;

  &::before {
    content: " ";
    display: block;
    background-color: #eef0f6;
    position: absolute;
    width: 40px;
    height: 70px;
    bottom: 30px;
  }

  &::after {
    content: " ";
    display: block;
    background-color: #ffffff;
    position: absolute;
    width: 50px;
    height: 50px;
    bottom: 68px;
    left: 0px;
    border-radius: 0 0 0 25px !important;
  }
}

.chatBoxAnimation2 {
  width: 100%;
  flex-grow: 1;
  background-color: #ffffff;
  border-radius: 30px;
  position: relative;

  &::before {
    content: " ";
    display: block;
    background-color: #eef0f6;
    position: absolute;
    width: 25px;
    height: 50px;
    bottom: 0;
    left: 190px;

    @include mobile-extra-min {
      left: 140px;
    }
  }

  &::after {
    content: " ";
    display: block;
    background-color: #ffffff;
    position: absolute;
    width: 25px;
    height: 50px;
    bottom: 0;
    left: 200px;
    border-radius: 0 0 0 20px !important;

    @include mobile-extra-min {
      left: 150px;
    }
  }
}

//for big display if we had now animation and animation2 classes
.add_flex_class {
  width: 100%;
  flex-grow: 1;
}

//styles for input and send button
.chat2 {
  border-radius: 32px;
  animation: chatLoc2 0.5s ease forwards;
  z-index: 2;
}

///styles for 'close card' button
.tagBtn {
  font-size: 1rem;
  color: var(--black);
  margin-right: 10px;
  @include mobile-min {
    margin-right: 0;
  }
}

@keyframes seen {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.seenTag {
  opacity: 1;
  animation: seen 1.5s ease forwards;
  margin-top: 30px;
  display: flex;
  justify-content: end;
}

.unseenTag {
  display: none;
  opacity: 0;
}

