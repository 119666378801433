@import '../../styles/helpers';

.menu {
  position: fixed;
  left: 50%;
  bottom: 10px;
  width: calc(100% - 2.6rem);
  transform: translate(-50%, 0);
  background-color: var(--white);
  border-radius: 16px;
  z-index: 100;


  &_cent {
    margin: 1.3rem auto;
    max-width: 360px;
    padding: 0 1.65rem;
  }
}

.link_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--grey-16);

  .icon {
    width: 24px;
    height: 24px !important;
    margin-bottom: 4px;
  }

  p {
    @include font(400, center, 'DM Sans', 1rem, 1.35rem);
  }
}

.active {
  color: var(--grey-17);
}

.link_wrapper.homes svg path {
  stroke: var(--grey-16) !important;
}

.link_wrapper.homes.active svg path {
  stroke: var(--grey-17) !important;
}

.link_wrapper.active svg path {
  fill: var(--grey-17);
}

.link_wrapper.guide.active svg path {
  fill: var(--white);
  stroke: var(--grey-17);
}