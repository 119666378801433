@import './../../styles/helpers';

.slimCard {
  border-radius: 25px;

  & > div:not(:last-child) {
    border-right: 1px solid var(--grey-8);
    @include mobile {
      border-right: 0;
    }
  }

}

.slimCardMobile {
  max-width: 760px;
}

.fixed_chat_button {
  //bottom: 2.5rem;
 // bottom: 9rem;
  right: 1.25rem;
  height: 5rem;
  width: 5rem;
  z-index: 1000;
}

//preferrences
.rationale {
  color: var(--main-black, #161a21);
  //@include font(400, start, 'Poppins', 0.875rem, 1.3rem);
  @include font(400, start, Poppins, .8rem, 1.2rem);
  @include mobile {
    @include font(400, start, Poppins, 1rem, 1.4rem);
  }
  @include mobile-min {
    @include font(400, start, Poppins, 1.2rem, 1.4rem);
  }
}

.fit {
  @include font(700, start, 'Poppins', 2.625rem, 2.9rem);

  @include mobile-min {
    @include font(700, start, 'Poppins', 1.5rem, 1.7rem);
  }
}

.fitIndicator {
  color: var(--green-highlight);
}

.strongFit {
  color: var(--green-highlight);
}

.partialFit {
  color: var(--blue-1);
}

.weakFit {
  color: var(--red-1);
}

.docs_and_quiz_wrapper {
  width: 100%;
  margin: 0 auto 7px;
  @include laptop {
    max-width: 760px;
  }
}

//for upload doc link
.link_wrapper {
  border: none!important;
  border-radius: 0!important;
  background: inherit!important;
  padding: 0!important;
  position: relative;
  width: 100%;
  cursor: pointer;

  &_title {
    position: absolute;
    left: 30px;
    top: 50%;
    width: 60%;
    transform: translate(0, -50%);

    @include font(500, start, 'Poppins', 1.5rem, 2rem);
    color: var(--white);
  }

  img {
    width: 100%;
  }

}