@import './../../../../styles/helpers';

.mobile_main {
  padding-top: 100px;
  padding-bottom: 101px;
}

.mobile_screen {
  min-height: 50vh;
}

//tour
.tour {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  cursor: pointer;

  &_info_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 31%;
    right: 12%;
    width: auto;

    &_title {
      @include font(400, center, 'Poppins', 2.5rem, 2.6rem);
      color: var(--white);

      @include mobile-extra-min {
        @include font(400, center, 'Poppins', 1.8rem, 2rem);
      }
    }

    &_label {
      width: auto;
      background-color: var(--grey-23);
      border-radius: 15px;
      color: var(--white);
      margin-top: 7px;
      padding: 0 8px;
      @include font(300, center, 'Poppins', .8rem, 2rem);
    }
  }


  img {
     width: 100%;
  }
}

//mobile menu
.menu {
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
  border-radius: 20px;
  padding: 27px 25px;
  background-color: var(--grey-19);

  &_item {
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;

    &_avatar {
      flex-shrink: 0;
      width: 54px;

      img {
        width: 100%;
      }
    }

    &_info {
      width: 100%;
      cursor: pointer;

      .title {
        color: var(--black-bg);
        @include font(400, start, 'Poppins', 1.6rem, 2.5rem);
      }

      .description {
        color: var(--grey-2);
        @include font(400, start, 'Poppins', 1rem, 1.5rem);
      }
    }
  }
}

//menu for work with images (make photos)
.photos_work_menu {
  margin: 0 auto;
  background-color: var(--white);
  color: var(--black);
  width: 100%;
  max-width: 700px;
  border-radius: 20px;
  overflow: hidden;

  &>div:first-child {
    border-bottom: 1px solid var(--grey-3);
  }

  div {
    cursor: pointer;
    padding: 18px 0;
    @include font(400, center, 'Poppins', 1.5rem, 2.25rem);
    @include mobile-min {
      @include font(400, center, 'Poppins', 2rem, 3rem);
    }
  }
}

//file uploader mw
.file_uploader_wrapper {
  width: 100%;
  background-color: white;
  padding: 20px;
}

.fileUploader {
  border: 1px dashed var(--grey-4);
  border-radius: 24px;
  padding: 2.5rem 3.75rem;

  input {
    width: 100%;
  }

  h3 {
    color: var(--black-bg, #161a21);
    @include font(400, center, 'Poppins', 1.125rem, 1.57rem);
  }

  p {
    color: var(--grey-2);
    @include font(400, center, 'Poppins', 0.75rem, 1.05rem);
  }

  u {
    color: var(--black-bg);
    text-decoration: underline;
  }
}

.images_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 30px;

  &>div {
    @include font(400, center, 'Poppins', 0.75rem, 1.05rem);
    width: 30%;
    @include mobile-min(){
      width: 48%;
    }
    @include mobile-extra-min() {
      width: 90%;
    }
  }
}

.image_item {
  width: 100%;
  //border: 1px solid red;

  &_header {
  //  border: 1px solid green;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    img, svg {
      cursor: pointer;
    }
  }
}

.controls_wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.btn {
  width: 50px;
  height: 50px;
  background-color: var(--grey-2);

  img, svg {
    width: 50%;
    height: 50%;
  }
}

.disabled {
  opacity: .5;
  cursor: auto!important;
}