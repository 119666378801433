@import './../../styles/helpers';

.main_wrapper {
  width: 100%;
  height: 100%;
  background: url('../../assets/onboarding/homeBuying/homeBuying_bg_large.png') top / cover;

  @include mobile-min {
    background: url('../../assets/onboarding/homeBuying/homeBuying_bg_small.png') top / cover;
  }
}