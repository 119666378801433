@import './../../styles/helpers';


.desktop_main {
  padding-top: 50px;
}

.mobile_main {
  padding-top: 6.125rem;
  padding-bottom: 101px;
  //background:
  //       // url('../../assets/Revised_NavBackground.svg') center / cover,
  //        lightgray;
}

.title {
  color: var(--white-text);
  @include font(700, center, 'Poppins', 2.625rem, 3.675rem);
}

.subtitle {
  color: var(--grey-2);
  @include font(400, start, 'Poppins', 1rem, 1.5rem);
}

//.tabs {
//  display: flex;
//  flex-shrink: 0;
//  border: 1px solid #b5b5c3;
//  border-radius: 200px;
//  height: 52px;
//
//  .tab {
//    display: flex;
//    position: relative;
//    justify-content: center;
//    align-items: center;
//    gap: 10px;
//    border-radius: 24px;
//    padding: 8px 20px;
//    color: var(--white-text);
//    @include font(400, center, 'Poppins', 1.125rem, 1.5rem);
//
//    &.current {
//      background: var(--black-bg);
//
//      /* workaround for the text size when bolding */
//      &:before {
//        position: absolute;
//        content: attr(data-text);
//        color: var(--black-text);
//        font-weight: 600;
//      }
//    }
//  }
//}

.dropdownHeaderAlign {
  max-width: 450px;

  .dropdownHeader {
    border: 1px solid var(--grey-7);
  }
}

.dropdown {
  border: 1px solid var(--grey-7);
  z-index: 5;
  max-height: 50vh;
  overflow-y: scroll;

  input {
    width: 1.125rem;
    height: 1.125rem;
  }

  img {
    width: 6.25rem;
    aspect-ratio: 2/1.4;
    border-radius: 10px;
  }
}

.footer {
  color: var(--grey-2);
  @include font(400, center, 'Poppins', 0.875rem, 1.5rem);
}