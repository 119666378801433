@import './../../styles/helpers';

.main_wrapper {
  width: 100%;
  height: 100%;
  background: url('../../assets/onboarding/propertyQuality/kitchen_bg_large.png') top / cover;

  @include mobile-min {
    background: url('../../assets/onboarding/propertyQuality/kitchen_bg_small.png') top / cover;
  }
}


.man {
  position: absolute;
  z-index: 5;
  width: 70%;
  top: 80px;
  transform: translate(-50%, 0);
  @include mobile-min {
    left: 0;
    width: 100%;
    transform: translate(0, 0);
  }
}

.quality {
  position: absolute;
  z-index: 2;
  width: 42%;
  top: 57px;
  left: 12%;
}

.appliances {
  position: absolute;
  z-index: 3;
  width:32%;
  top: 120px;
  right: 9%;
}

.size {
  position: absolute;
  z-index: 1;
  width:38%;
  top: 250px;
  left: 6.5%;
}

.light {
  position: absolute;
  z-index: 4;
  width:26%;
  top: 260px;
  right: 3%;
}