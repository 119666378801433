@import '../../../styles/helpers';

.properties_block {
  gap: calc(1% / 2);
 // max-height: 80vh;
  //overflow-y: auto;
 // scroll-behavior: smooth;
  @include laptop-min {
    gap: 1%;
  }
}

.property_wrapper {
  width: 100%;
  max-width: 350px;
 // width: 33%;
  margin-bottom: 10px;

  @include laptop-min {
    max-width: 370px;
    //width: 49%;
    width: 100%;
  }

  @include mobile-min {
    width: 100%;
    max-width: 350px;
  }
}
