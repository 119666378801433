@import './../../../../../styles/helpers';

.webcam_component {
  height: 90vh;
  background-color: var(--white);
}

.close_btn {
  top: 30px;
  right: 20px;
}

.webcam_wrapper {
  width: 100%;
  aspect-ratio: 16 / 9;
  max-height: 70vh;

  img, video {
    max-width: 100%;
    height: 100%;
  }
}

.icon_wrapper {
  width: 50px;
  height: 50px;
  background-color: var(--grey-2);

  img, svg {
    width: 50%;
    height: 50%;
  }
}