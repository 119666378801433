@import './../../../../../styles/helpers';


//mobile menu
.menu {
  width: 100%;
  border: 1px solid var(--grey-7);
  border-radius: 20px;
  margin-bottom: 20px;

  &_item {
    position: relative;
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 24px 25px;

    &_avatar {
      flex-shrink: 0;
      width: 54px;

      img {
        width: 100%;
      }
    }

    &_info {
      width: 100%;
      cursor: pointer;

      .title {
        color: #161A21;
        @include font(400, start, 'Poppins', 1.25rem, 1.875rem);
      }

      .description {
        color: var(--grey-2);
        @include font(400, start, 'Poppins', .75rem, 1.125rem);
      }
    }

    &:not(.current) > &_avatar > img {
      filter: grayscale(100%);
    }

    &:not(.current) + &:not(.current)::before {
      position: absolute;
      top: 0;
      flex-shrink: 0;
      background: var(--grey-7);
      width: 85%;
      height: 1px;
      content: '';
    }

    &.current {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
      border-left: 4px solid black;
      border-radius: 24px;
      background: var(--white);

      .title {
        color: var(--black-bg);
        font-weight: 600;
      }

      &_avatar {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }
}