
.swipe_block {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.test_right,
.test_left {
  position: absolute;
  width: 39%;
  top: 0;
  bottom: 0;
  cursor: pointer;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.test_right {
  right: -40%;
}

.test_left {
  left: -40%;
}
