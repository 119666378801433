body {

  //h1, h2, h3, h4, h5, h6, strong {
  //  font-weight: 600;
  //  color: var(--bs-primary);
  //}

  a {
    text-decoration: none;

    &:focus-visible, &:focus {
      outline: none;
    }
  }


  .form-control, .form-select, .form-check-input {
    &:focus-visible, &:focus {
      border-color: var(--bs-border-color)
    }
  }
  .form-control, .form-control.is-invalid, .form-select, .form-select.is-invalid, .form-check-input, .form-check-input.is-invalid {
    &:focus-visible, &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .form-control {
    padding: 0.5rem 0.75rem;
  }
  .form-select {
    padding: 0.5rem 2.25rem 0.5rem 0.75rem
  }
  .form-check-input {
    width: 1.2rem;
    height: 1.2rem;
  }

  .form-label {
    margin-bottom: 0.1rem;
    color: #667085;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .btn-close:focus {
    box-shadow: none;
  }

  .btn {
    padding: 0.625rem 1.125rem;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-width: 2px;
  }
  .btn-sm {
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 0.875rem;
    --bs-btn-font-size: 0.875rem;
    display: flex;
    gap: 0.75rem;
  }
  //.btn-primary {
  //  path {
  //    fill: var(--bs-white);
  //  }
  //}

  //.btn-close {
  //  --bs-btn-close-bg: url(../assets/images/btn-close.svg);
  //  width: 1.25em;
  //  height: 1.25em;
  //  --bs-btn-close-opacity: 1;
  //}

  .gap-0 {
    gap: 0;
  }
  .gap-1 {
    gap: 0.25rem !important;
  }
  .gap-2 {
    gap: 0.5rem !important;
  }
  .gap-3 {
    gap: 1rem !important;
  }
  .gap-4 {
    gap: 2rem !important;
  }
  .gap-5 {
    gap: 2.5rem !important;
  }


  //.modal-backdrop {
  //  background: rgba(221, 224, 231, 0.60);
  //  backdrop-filter: blur(10px);
  //  --bs-backdrop-opacity: 1;
  //}
  //.offcanvas-backdrop {
  //  background: rgba(221, 224, 231, 0.60);
  //  backdrop-filter: blur(10px);
  //  &.show {
  //    opacity: 1;
  //  }
  //}
  //.modal {
  //  .modal-header {
  //    border-bottom: none;
  //    padding: 2rem 2rem 0 2rem;
  //  }
  //  .modal-body {
  //    padding: 2rem;
  //  }
  //  .modal-content {
  //    background-color: #fff;
  //    border: none;
  //    border-radius: 0;
  //  }
  //}

}