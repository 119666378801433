@import './../../styles/helpers';

.intro1_bg {
    background: linear-gradient(#82a4a3, #9dbcb6);
    height: 200rem;
    width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.intro2_bg {
    background: linear-gradient(#6a9176, #86a090);
    height: 200rem;
    width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}