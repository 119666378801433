@import '../../../styles/helpers';

.properties_block {
  display: flex;
  flex-wrap: nowrap;
  gap: calc(2% / 3);
}

.property_type_wrapper {
  width: 24.5%;


  .title {
    margin-bottom: 10px;
    width: 100%;
    color: var(--white);
    background-color: var(--black);
    border-radius: 15px;
    @include font(400, center, 'Poppins', 1.5rem, 2.625rem);
  }

  .property {
    width: 100%;
  }

}


