@import './../../styles/helpers';

.contents_wrapper {
    width: 100%;
    max-width: 700px;
    align-items: center;
}

.description {
    margin-bottom: 1rem;
    @include font(400, center, 'Poppins', 1.5rem, 2.1rem);

    @include laptop-min {
        @include font(400, center, 'Poppins', 1.125rem, 1.57rem);
    }
}

.main_content {
    width: 100%;
    flex-wrap: nowrap;
}

.image {
    width: 40%;
    aspect-ratio: 1/1;

    @include mobile-min {
        display: none;
    }
}
.questions_wrapper {
    width: 57%;
    margin-left: 3%;

    @include mobile-min {
        width: 100%;
    }
}

.continueBtn {
    width: 100%;
    max-width: 200px;
    padding: 1.25rem 0!important;
    font-size: 1rem;
}

.mcq {
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0.625rem 0;
    font-size: 1.125rem;
}

.choose,
.chose {
    width: 2.188rem;
    height: 2.188rem;
    position: relative;
    flex-shrink: 0;
    border: 0;
    border-radius: 50%;
    margin-right: 1rem;
    background-color: var(--orange);
}

.chose::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
    border-radius: 50%;
    background-color: var(--white);
}