@import '../../styles/helpers';

.kanban_screen {}

.layout {
  main {
    border-radius: 0;
    padding-top: 1.25rem;
  }
}

.mobile_main {
  padding-top: 7.5rem!important;
  padding-bottom: 101px;
}


.search_and_filter_wrapper {
  padding: 10px 0 10px 10px;
  background-color: var(--white);
  border-radius: 15px;
}

.search_block {
  max-width: 650px;
  margin: 0 auto 10px;
}


.addressInputBar {
  flex-basis: auto;
  border: 1px solid var(--grey-18);
  border-radius: 15px;
  padding: 2px 0 2px 9px;
  margin-right: 1rem;

  .input {
    @include font(300, start, 'Poppins', 1rem, 2rem);
    color: var(--grey-18);
  }
}

.add_btn {
  width: 3.125rem;
  height: 2.25rem;
  background-color: var(--black);
  border-radius: 15px;
}

.filters_block {
  overflow: auto;
  webkit-overflow-scrolling: touch;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.filter_tab {
  flex-shrink: 0;
  cursor: pointer;
  touch-action: manipulation;
  user-select: none;
  border-radius: 15px;
  border: 1px solid #ADADAD;
  background-color: var(--white);
  color: #ADADAD;
  padding: 0 1rem;
  @include font(400, center, 'Poppins', 1rem, 2.625rem);
}

.active_tab {
  background-color: var(--black)!important;
  color: var(--white)!important;
  border: none!important;
}

.main_context_wrapper {
  width: 100%;
  margin: 20px 0;
}

.footer {
  color: var(--grey-2);
  @include font(400, center, 'Poppins', 0.875rem, 1.5rem);
}

.plan_modal_wrapper {
  border: 2px solid fuchsia;
  width: 100%;
  max-width: 800px;
  background:
            url('../../assets/Revised_NavBackground.svg') center / cover,
          white;
  //  lightgray;
  padding-top: 1.25rem;

}

.subscription_link_modal {
  width: 100%;
  padding: 30px;

  img {
    width: 60%;
  }

  a {
    @include font(500, center, 'Poppins', 1rem, 1.5rem);
  }
}


.close_btn {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  top: 1.563rem;
  right: 1.875em;
  background: url('../../assets/kanbanScreen/X.png');
  background-size:  cover;
}

.title {
  color: var(--white-text);
  @include font(500, center, 'Poppins', 1rem, 1.5rem);
}