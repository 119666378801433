@import './../../../../styles/helpers';

.smallCardsContainer {
  flex-basis: auto;

  .card_header {
    padding-left: 1.875rem !important;
    font-size: 1rem;
    @include font(600, start, 'Poppins', 1.5rem, 2rem);
  }

  .card_body_description {
    box-sizing: border-box;
    height: 65px;
    overflow-y: auto;
    overflow-x: hidden;
    /* Скрываем горизонтальную прокрутку */
    margin-bottom: 10px;
    @include font(400, start, Poppins, .8rem, 1.2rem);

    @include mobile {
      @include font(400, start, Poppins, 1rem, 1.4rem);
    }

    @include mobile-min {
      @include font(400, start, Poppins, 1.2rem, 1.4rem);
    }
  }
}

.price_container {
  display: flex;
}

.page_container {
  position: absolute;
  bottom: 1.875rem;
  left: 50%;
  width: calc(100% - 3.75rem);
  transform: translate(-50%, 0);
  display: flex;

  div {
    min-width: 70px;
    background-color: var(--grey-21);
    border-radius: 20px;
    gap: 4px;
    padding: 4px 7px;
    color: var(--black);
    @include font(500, center, Poppins, .7rem, 2rem);

    @include mobile {
      @include font(500, center, Poppins, .8rem, 2rem);
    }
  }

  img {
    //max-width: 100px;
    //width: 40%;
    height: 60px;
  }
}


.icon_wrapper {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-20);
  width: 24px;
  height: 24px;
  border-radius: 50px;
  margin-right: 8px;

  svg,
  img {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    margin-right: 0 !important;
    color: var(--white)
  }
}