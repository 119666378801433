@import './../../styles/helpers';

.mobile_main {
  padding-top: 6.5rem;
  padding-bottom: 101px;
}

.title {
  color: var(--white-text);
  @include font(700, start, 'Poppins', 2.625rem, 3.675rem);
}

.subtitle {
  color: var(--grey-2);
  @include font(400, center, 'Poppins', 1rem, 1.5rem);
}

.tabs {
  border: 1px solid var(--grey-7);

  .tab {
    color: var(--white-text);
    @include font(400, center, 'Poppins', 1.125rem, 1.5rem);
    @include mobile-min {
      @include font(400, center, 'Poppins', .8rem, 1rem);
    }

    &.current {
      background: var(--black-bg);
      /* workaround for the text size when bolding */
      &:before {
        position: absolute;
        content: attr(data-text);
        color: var(--black-text);
        font-weight: 600;
      }
    }
  }
}

.dropdownHeader {
  border: 1px solid var(--grey-7);

  &_content {
    p {
      @include font(400, center, 'Poppins', 1.125rem, 1.7rem);
      width: 230px;
      margin-right: 1rem;
    }

    img {
      width: 2.2rem;
      height: 2.2rem;
    }
  }

  @include mobile-min {
    max-width: 345px;
    width: 100%;
    &_content p {
      margin-right: 0;
      width: calc(100% - 2.2rem)
    }
  }
}

.dropdown {
  width: 100%;
  margin-top: 10px;
  height: 345px;
  overflow-y: scroll;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  border: 1px solid var(--grey-7);
  border-radius: 10px;
  background-color: var(--white);
}

.dropdown_content {
  width: 96%;
  margin: 0 2%;
}

.dropdown_item {
  margin: 10px 0;

  &_img {
    width: 30%;
    border-radius: 10px;
  }

  &_content {
    width: 65%;

    p {
      @include font(400, start, 'Poppins', 1rem, 1.5rem);
    }
  }
}

.footer {
  color: var(--grey-2);
  @include font(400, center, 'Poppins', 0.875rem, 1.5rem);
}

//sharing modal window styles
.content_container {
  max-width: 600px;
  @include mobile {
    max-width: 85%;
    margin: 0 auto;
  }

  .image {
   // width: 35%;
    width: 55%;
    max-width: 330px;
    margin-bottom: 2rem;
  }

  .collab_title {
  //  max-width: 600px;
    color: var(--black-bg, #161a21);
   // @include font(700, center, 'Poppins', 2rem, 2.8rem);
    @include font(700, center, 'Poppins', 2.5rem, 3.6rem);
    @include laptop {
      @include font(700, center, 'Poppins', 2rem, 2.5rem);
    }
  }

  .collab_subtitle {
   // max-width: 600px;
    padding: 8px 0 26px 0;
   // padding: 1rem 0;
    color: var(--grey-2);
    @include font(400, center, 'Poppins', 1rem, 1.4rem);
  }

  .parentBox {
    width: 100%;
    border: 1px solid var(--grey-7);

    .sendInvite {
      background-color: var(--black-2);
      font-size: 0.813rem;
      white-space: nowrap;

      &:disabled {
        background-color: var(--grey-2);
      }
    }
  }

  .input {
    @include font(500, start, 'Poppins', 1rem, 1.5rem);
    &::placeholder {
      @include font(500, start, 'Poppins', 1rem, 1.5rem);
    }
  }

  .collaborators {
    margin-top: 1.875rem!important;

    h6 {
      @include font(600, start, 'Poppins', 1rem, 1.375rem);
    }

    .collab_container {
      max-height: 150px;

      h2 {
        @include font(400, center, 'Poppins', 1.5rem, 1.5rem);
        @include mobile-min {
          font-size: 1rem;
        }
      }
    }

    .collaborator_item_border {
      border-bottom: 1px solid var(--grey-24);
    }
  }

}
