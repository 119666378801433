@import './../../styles/helpers';

.continueBtn {
    width: 100%;
    max-width: 200px;
    padding: 1.25rem 0!important;
    font-size: 1rem;
}

.description {
    margin-bottom: 0.625rem;
    @include font(400, center, 'Poppins', 1.5rem, 2.1rem);

    @include laptop-min {
        @include font(400, center, 'Poppins', 1.125rem, 1.57rem);
    }
}

.warning {
    background-color: var(--orange-2);
    border: 1px solid var(--orange-2);
    border-radius: 25px;
}

.price_block {
    width: 90%;
    justify-content: center;
}

.price_tag {
    color: var(--white);
    @include font(400, center, 'Poppins', 4.5rem, 4.5rem);

    @include mobile {
        font-size: 2.25rem;
    }
}

.tag {
    width: 80%;
    color: var(--white);
    font-style: italic;
    @include font(400, center, 'Poppins', 1.5rem, 2.1rem);

    @include mobile {
        @include font(400, center, 'Poppins', 1.125rem, 1.57rem);
    }
}