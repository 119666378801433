@import './../../styles/helpers';

.input {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    border: 1px solid #e0e0ea;
    border-radius: 200px;
    background: white;
    padding: 0px 40px;
    width: 120%;
    height: 50px;
    color: var(--white-text);
    font-style: normal;
    font-weight: 400;
   // font-size: 14px;
    line-height: 24px;
    /* 133.333% */
    font-family: Poppins;

    &::placeholder {
        color: #6d6d77;
    }
}

.form_check_edit {
    padding-left: 2.5rem!important;
    padding-right: 2.5rem!important;
}

.contents_wrapper {
    width: 100%;
    height: 60rem;
    justify-content: start;
    max-width: 700px;
    align-items: center;

    input, label {
        font-size: 16px !important;
    }
}

.title {
    color: white;
}

.bg_1 {
    background: linear-gradient(#FFF1EB, #EEE5E0);
    height: 200rem;
    width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bg_2 {
    background: linear-gradient(#c68e8e, #ee5656);
    height: 200rem;
    width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bg_3 {
    background: linear-gradient(#E3C297, #D1C9BC);
    height: 200rem;
    width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bg_4 {
    background: linear-gradient(#83B5C7, #89BBC3);
    height: 200rem;
    width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cue {
    width: 100%;
    border-radius: 50px;
}

.status_bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 10px 0;
}

.step {
    width: 20%;
    height: 4px;
    background-color: rgb(179, 179, 179);
    border-radius: 2px;
}

.continueBtn {
    width: 15rem;
}

.custom_scrollbar::-webkit-scrollbar {
    width: 9px !important;
    border-radius: 10px;
    /* Adjust this value to make the scrollbar wider */
}

/* Optional: Styling for the scrollbar track */
.custom_scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    /* Track color */
}

/* Styling for the scrollbar thumb */
.custom_scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Thumb color */
    border-radius: 10px;
    /* Optional: round the corners */
}

/* Optional: Thumb color on hover */
.custom_scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Change thumb color on hover */
}