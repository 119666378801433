:root {
    --blue-bg: #d0ebff;
    --blue-highlight: #1f94ea;
    --blue-text: #2875ad;

    --green-bg: #b8ecc3;
    --green-highlight: #13ab35;
    --green-text: #0c6b21;

    --orange-bg: #ffde92;
    --orange-highlight: #ff7a2f;
    --orange-text: #ab430a;

    --white-bg: #ffffff;
    --white-highlight: #ffffff;
    --white-text: #18181a;

    --black-bg: #161a21;
    --black-highlight: #ffffff;
    --black-text: #ffffff;

    --box-shadow-1: rgba(24, 24, 26, 0.02);
    --background-1: rgba(255, 255, 255, 0.34);


    //additional colors
    --black: black;
    --white: white;
    --orange: #D46827;
    --orange-2: rgb(255, 136, 69);
    --orange-3: #FF8845;
    --black-1: #18181a;
    --black-2: #1f1f1f;
    --grey-1: #e0e0ea;
    --grey-2: #6d6d77;
    --grey-3: #d9d9d9;
    --grey-4: #c5c5d0;
    --grey-5: #eef0f6;
    --grey-6: #737384;
    --grey-7: #b5b5c3;
    --grey-8: #ccc;
    --grey-9: #d6d6d6;
    --grey-10: #f3f3f3;
    --grey-11: #6a6f71;
    --grey-12: #6c6c7e;
    --grey-13: #b5b5b5;
    --grey-14: #eaeaed;
    --grey-15: #f5f5f5;
    --grey-16: #9D9D9D;
    --grey-17: #2B2B2B;
    --grey-18: #ADADAD;
    --grey-19: #F7F7F7;
    --grey-20: #B1B1C2;
    --grey-21: #F4F4F4;
    --grey-22: #2B2B2B;
    --grey-23: #313131;
    --grey-24: #EBECF1;

    --pink-1: #f9c2c2;

    --brown-1: #9c5e02;

    --blue-1: #348BF0;
    --blue-2: #d0e6ff;
    --blue-3: #D3EAFA;
    --blue-4: #054362;
    --blue-5: #044464;

    --green-1: #2eb04a;

    --red-1: #e4281b;
    --red-2: #E22030;
    --red-3: #FF4C5A;

    --survey-energy: #C4433E;
    --survey-maitenance: #D1A587;
    --survey-amenities: #C9865F;
    --survey-home: #3C7077;
    --survey-light: #5B6169;
    --survey-school: #FB8818;
    --survey-curbAppel: #54251C;
}

.accent-blue {
    --bg-color: var(--blue-bg);
    --highlight-color: var(--blue-highlight);
    --text-color: var(--blue-text);
}

.accent-green {
    --bg-color: var(--green-bg);
    --highlight-color: var(--green-highlight);
    --text-color: var(--green-text);
}

.accent-orange {
    --bg-color: var(--orange-bg);
    --highlight-color: var(--orange-highlight);
    --text-color: var(--orange-text);
}

.accent-white {
    --bg-color: var(--white-bg);
    --highlight-color: var(--white-highlight);
    --text-color: var(--white-text);
}

.accent-black {
    --bg-color: var(--black-bg);
    --highlight-color: var(--black-highlight);
    --text-color: var(--black-text);
}

.accent-inherit {
    --bg-color: inherit;
    --highlight-color: var(--black-highlight);
    --text-color: inherit;
}