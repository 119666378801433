@import './../../styles/helpers';

.info {
    margin-top: 0.75rem;
}

.info span:not(:last-child) {
  margin-right: 8px;
  border-right: 1px solid var(--grey-1);
  padding-right: 6px;
}

.info b {
  color: var(--white-text);
  @include font(500, start, 'Poppins', 1rem, 1.5rem);
}

.imageContainer {
  border-radius: 24px;
  background: var(--grey-3);

  .overlay {
    opacity: 0;
    transition: opacity var(--animation-time) ease-in-out;
    background: var(--background-1);
  }

  .mainImage {
    width: 100%;
    aspect-ratio: 1 / .75;
    object-fit: fill;
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
  }
}

.address {
  &:hover {
    text-decoration: underline;
  }
}

.address2 {
  color: var(--white-text);
  @include font(600, start, 'Poppins', 1.25rem, 1.75rem);
}

.address1 {
  color: var(--grey-2);
  @include font(400, start, 'Poppins', 1.125rem, 1.7rem);
}

.card_btn {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}
