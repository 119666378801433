@import './../../styles/helpers';

.main_wrapper {
  width: 100%;
  height: 100%;
  background: url('../../assets/onboarding/perfectFit/perfectFit_bg_large.png') center / cover;

  @include mobile-min {
    background: url('../../assets/onboarding/perfectFit/perfectFit_bg_small.png') center / cover;
  }
}