@import './../../styles/helpers';

.content {
  @include font(400, center, 'Poppins', 1.125rem, 1.7rem);

  a {
    color: var(--grey-13);
  }
}

.links_wrapper {
  margin-top: 2.5rem;

  a {
    color: var(--grey-13);
  }
}
