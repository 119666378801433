@import './../../styles/helpers';

.main_wrapper {
  width: 100%;
  height: 100%;
  background: url('../../assets/onboarding/homeSearch/homeSearch_bg_large.png') center / cover;
//  background: url('../../assets/onboarding/homeSearch/homeSearh_bg_small.png') center / cover;

  @include mobile-min {
    background: url('../../assets/onboarding/homeSearch/homeSearh_bg_small.png') center / cover;
  }
}


.right_images_wrapper {
  position: absolute;
  z-index: 2;
  width: 60.5%;
  top: 9%;
  right: 20px;

  img {
    width: 100%;
  }

  &>img:first-child {
    width: 95%;
  }
}


.people {
  z-index: 3;
  position: absolute;
  top: 24.5%;
  left: 0;
  width: 55%;

  @include mobile-min {
   top: 200px
  }
}


