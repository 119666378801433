@import './../../styles/helpers';

.p_4 {
  position: relative;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
}

.w_full {
  overflow: visible;
}

.text-sm {
  color: #263238;
  @include font(600, center, Poppins, 0.75rem, 0.875rem);
}

.total {
  position: absolute;
  bottom: 0;
  @include font(500, center, Poppins, 2rem, 2.5rem);
}
