//@import 'bootstrap/dist/css/bootstrap.min.css';

// scss-docs-start import-stack
// Configuration
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

@import "bootstrap/scss/root";

// Override variables
@import "styles/variables";

@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
// @import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// Custom styles
@import 'styles/reset';
@import 'styles/colors.scss';
@import 'styles/animations.scss';
@import 'styles/helpers';
@import 'styles/override';

//fonts
@import 'assets/fonts/fonts';

@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');
/* Jua */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
/* Poppins */
@import url('https://fonts.googleapis.com/css2?family=Share:ital@1&display=swap');
/* Share */

.App {
    position: absolute;
    background: white;
    width: 100%;
    min-height: 100%;
}


:root {
    font-size: 16px;

    @include mobile {
        font-size: 14px;
    }

    @include mobile-min {
        font-size: 12px;
    }
}

* {
    box-sizing: border-box;
    margin: 0;
    border: none;
    background: none;
    padding: 0;
    color: inherit;
    text-decoration: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

a,
button {
    display: flex;
    cursor: pointer;
}

html,
body,
#root {
    position: absolute;
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


}

::-webkit-scrollbar {
    width: 3px !important;
}

::-webkit-scrollbar-track {
    background: #e4e4e4;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

//for swiper
.swiper .swiper-wrapper .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.swiper-button-disabled {
    opacity: .5;
}

#photos.swiper .swiper-wrapper .swiper-slide {
    width: 170px!important;
    user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#large_photos.swiper .swiper-wrapper .swiper-slide {
    width: 100%!important;
    border-radius: 25px;
    user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    img {
        border-radius: 25px;
    }
}
