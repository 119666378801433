@import './../../styles/helpers';

.sidebar {
 // border: 1px solid red;
  position: sticky;
  top: 0;
  width: 18.75rem;
  //min-height: 90vh;
  //margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  //box-shadow: 0px 16px 20px -2px rgba(0, 0, 0, 0.15);
  //border-radius: 0px 15px 15px 0px;
  @include font(400, center, 'Poppins', 1rem, 1.35rem);
  @include mobile {
    width: 16rem;
  }
  @include mobile {
    min-height: auto;
  }

  & > div {

    &:nth-child(2) {
      flex-grow: 1;
    }

  }
}

.menu_item {
 // border: 1px solid blue;
  display: flex;
  align-items: center;
  padding: 1.875rem 0 1.875rem 2.1875rem;
  cursor: pointer;

  .icon {
  //  border: 1px solid fuchsia;
    width: 24px;
    height: 24px !important;
    margin-bottom: 4px;
  }

  & > div {
    cursor: pointer;
  }

  & > div:last-child {
    margin-left: 1rem;
  }
}

.menu_item.logo {
  padding: 30px 0!important;
  margin: 0 auto;
}