@import './../../styles/helpers';

.layout {
    main {
        padding-top: 3.125rem;
    }
}

.title {
    color: var(--white-text);
    @include font(700, start, 'Poppins', 2.625rem, 3.675rem);

    @include mobile-min {
        @include font(700, start, 'Poppins', 1.75rem, 2.4rem);
    }
}

.subtitle {
    color: var(--grey-2);
    @include font(400, start, 'Poppins', 1.125rem, 1.5rem);
}
