@import './../../styles/helpers';


.card {
    position: relative;

    &.autoBox {
        width: auto;
        height: auto;
    }

    &.smallBox {
        width: 100%;
        max-width: 329px;
        min-height: 325px;

        @include laptop {
            max-width: 375px;
        }
    }

    &.bigLong {
        max-width: 672px;
        width: 100%;
        height: 325px;
    }

    &.bigBox {
        max-height: 664px;
        max-width: 670px;
    }

    .hoverImage {
        fill: var(--bg-color);
    }

    .background {
        position: absolute;
        z-index: 1;
        border-radius: 32px;
        background: var(--bg-color);
        width: 100%;
        height: 100%;
    }

    .header {
        z-index: 4;
        margin-top: 1.875rem;
        color: var(--text-color);
        @include font(600, start, 'Poppins', 1.5rem, 2rem);
    }

    .contents {
        width: 100%;
        z-index: 2;
        padding: 1.875rem;
    }

    .link {
        display: flex;
        position: absolute;
        z-index: 5;
        transition: all var(--animation-time) ease-in-out;
        margin: 1.875rem;
        width: 0;
        height: 0;

        .linkIcon {
            width: 100%;
            height: 100%;
        }
    }

    &.hasLink.addHover {
        z-index: 99;

        .background {
            filter: drop-shadow(0px 2px 4px rgba(24, 24, 26, 0.18));
            background: transparent;
        }

        .link {
            margin: 0;
            width: 62px;
            height: 62px;
        }
    }

    &.hasLink {
        &:hover {
            z-index: 99;

            .background {
                filter: drop-shadow(0px 2px 4px rgba(24, 24, 26, 0.18));
                background: transparent;
            }

            .link {
                margin: 0;
                width: 62px;
                height: 62px;
            }
        }

        &.linkTopRight {
            .link {
                top: 0;
                right: 0;
            }
        }

        &.linkBottomLeft {
            .link {
                bottom: 0;
                left: 0;
                margin: 50px;
            }

            &:hover {
                .link {
                    margin: 0;
                    width: 100px;
                    height: 100px;
                }
            }
        }

        &.linkBottomRight {
            .link {
                right: 0;
                bottom: 0;
            }
        }
    }
}