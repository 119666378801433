
.properties_wrapper {
  width: 100%;
  overflow-y: auto;
  min-height: 70vh;
  max-height: 70vh;

  &>div:not(:last-child) {
    margin-bottom: 10px!important;
  }
}