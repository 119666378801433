@import './../../styles/helpers';

.cards {
  @media (max-width: 1090px) {
    justify-content: center !important;
  }
}

.chartCardContainer {
  width: 100%;
  max-width: 670px;

  @media (max-width: 1094px) {
    max-width: 100%;
    width: 100%;
    display: block;
  }
}

.fitIndicator {
  color: var(--green-highlight);
  @include font(700, center, 'Poppins', 2.625rem, 2.887rem);
}

//  card
.chartBlueContent {
  @media (max-width: 530px) {
    display: flex;
    justify-content: end;
    width: 100%;
  }
}

.chartGreenCurve {
  P {
    background-color: var(--green-highlight);
    padding: 6px 20px 3px 20px;
    border-radius: 10px 10px 10px 0;
    color: var(--green-bg);
    max-width: 240px;
    min-width: 205px;
    text-align: left;
    font-family: "Poppins", sans-serif;
  }

  .fit {
    position: relative;
    background-color: #13ab35;
    padding: 0 10px 0 4px;
    border-radius: 0 0 10px 10px;
    margin-top: -6px;
    color: var(--white);
    max-width: 210px;
    min-width: 180px;
    @include font(600, center, 'Poppins', 2rem, 2rem);

    &::after {
      content: "";
      position: absolute;
      display: block;
      background-color: var(--white);
      width: 20px;
      height: 20px;
      right: -20px;
      top: 6px;
      border-radius: 10px 0 0 0;
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      background-color: var(--green-highlight);
      width: 20px;
      height: 20px;
      right: -10px;
      top: 0;
    }
  }
}

.chartBlueCurve {
  background-image: url('../../assets/icons/chartBlueCurve.svg');
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--white);
  border-radius: 10px;
  line-height: 1.1;
  font-family: "Poppins", sans-serif;
  min-height: 72px;

  h2 {
    color: var(--white);
    width: 100%;
    max-width: 200px;
    @include font(600, end, 'Poppins', 2rem, 2rem);
  }

  .cardContentCruve {
    color: var(--blue-2);
    max-width: 200px;
    padding: 6px 0 2px 40px;
    width: 100%;
    font-family: "Poppins", sans-serif;
  }
}

//.smallBoxes {
//  display: flex;
//  flex-direction: column;
//  width: 100%;
//  align-items: baseline;
//  gap: 5px;
//  padding: 15px 25px !important;
//
//  .HouseCurve {
//    background-image: url('../../assets/icons/houseCurveBg.svg');
//    background-size: cover;
//    background-repeat: no-repeat;
//    text-align: left;
//    padding: 7px 15px;
//    color: white;
//    border-radius: 10px 10px 10px 10px;
//    line-height: 1.1;
//    font-family: "Poppins", sans-serif;
//    min-height: 72px;
//    width: 100%;
//
//    p {
//      color: #161A21;
//      font-weight: 600;
//      padding-right: 50px;
//      text-align: left;
//      width: fit-content;
//      font-family: "Poppins", sans-serif;
//      max-width: 240px;
//    }
//
//    h2 {
//      color: #C5795F;
//      font-size: 35px;
//      font-family: "Poppins", sans-serif;
//      max-width: 260px;
//    }
//  }
//
//  .smallBoxHomeBorder {
//    width: 100%;
//    height: 125px;
//    border: 1px solid #13AB35;
//    border-radius: 10px;
//    position: relative;
//    box-shadow: 1px 1px 10px #13ab3580;
//
//    div {
//      width: 90%;
//      position: absolute;
//      height: 100px;
//      border: 1px solid #2875AD;
//      border-radius: 5px;
//      bottom: 0;
//      box-shadow: 1px 1px 10px #2875ad99;
//    }
//  }
//
//  .spaceReastBox {
//    height: 140px;
//    overflow-y: auto;
//    width: 100%;
//
//    h2 {
//      font-size: 35px;
//      background-color: #348BF0;
//      padding: 0px 10px;
//      border-radius: 10px;
//      color: white;
//      margin-bottom: 5px;
//      width: fit-content;
//      display: flex;
//      align-items: center;
//      gap: 5px;
//      font-family: "Poppins", sans-serif;
//      max-width: 300px;
//      font-size: 42px;
//
//
//      small {
//        font-size: 20px;
//        font-weight: 600;
//        font-family: "Poppins", sans-serif;
//      }
//    }
//
//    p {
//      text-align: left;
//      font-family: "Poppins", sans-serif;
//      font-size: 14px;
//
//    }
//
//    h4 {
//      text-align: left;
//      font-family: "Poppins", sans-serif;
//      font-size: 14px;
//    }
//  }
//
//  .solarContentBox {
//    background-image: url('../../assets/icons/solarGreenCurve.svg');
//    background-size: cover;
//    background-repeat: no-repeat;
//    text-align: left;
//    padding: 5px 15px;
//    color: white;
//    border-radius: 10px 10px 15px 18px;
//    font-family: "Poppins", sans-serif;
//    min-height: 72px;
//    width: 100%;
//    display: flex;
//    align-items: end;
//    margin-bottom: 5px;
//
//    h2 {
//      color: white;
//      font-size: 35px;
//      font-family: "Poppins", sans-serif;
//      max-width: 160px;
//      font-size: 42px;
//      min-width: 120px;
//    }
//
//    p {
//      color: #b8ecc3;
//      padding-bottom: 6px;
//      font-family: "Poppins", sans-serif;
//      max-width: 190px;
//    }
//  }
//}
//
//.bg_tranprant {
//  background-color: transparent !important;
//}
//
//.walkabilityBg {
//  background-image: url("../../assets/cards/walkabilityBgImg.svg");
//  width: 100%;
//  background-size: 100% 100%;
//
//  .alkabilityCurve {
//    background-image: url('../../assets/icons/walkabilityCurveIcon.svg');
//    background-size: cover;
//    background-repeat: no-repeat;
//    text-align: left;
//    padding: 5px 15px;
//    color: white;
//    border-radius: 10px 10px 6px 10px;
//    line-height: 1.1;
//    font-family: "Poppins", sans-serif;
//
//    h2 {
//      font-weight: 600;
//      font-size: 20px;
//      max-width: 200px;
//    }
//  }
//}


.circleScore {
  float: left;
  width: 3.75rem;
  height: 3.75rem;
}

.rationale {
  color: var(--main-black, #161a21);
  @include font(400, start, 'Poppins', 0.875rem, 1.3rem);
  @include mobile-min {
    @include font(400, start, 'Poppins', 1rem, 1.5rem);
  }
}

.preferenceCard {
  .circleScore {
    margin: 0px 15px 6px 0px;
  }
}

.chartCardAlign {
  flex-basis: auto !important;
}

