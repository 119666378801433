@import './../../../../styles/helpers';

.main_screen {
  // border: 1px solid #0e8f80;
  // min-height: 50vh;
}

.menu_wrapper {
  align-self: flex-start;
  flex-shrink: 0;
  width: 325px;
  // border: 2px solid fuchsia;
}

//это просто обертка главного контента с флекслм
.showBigCards {
  // border: 2px solid green;
  align-items: stretch !important;
  flex-grow: 1;
}

.chat_block_wrapper {
  width: calc(100% - 350px);
  // border: 2px solid hotpink;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.cards_wrapper_with_big_block {
  // border: 1px solid red;
  width: 350px;
  max-height: 665px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
}

.cards_wrapper_full_width {
  // border: 2px solid #057681;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  &>div {
    // border: 2px solid #28c90a;
  }
}


.features_block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 350px;
}

.feature_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid green;
  background-color: #cca7a7;
  width: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
  margin-bottom: 12px;

  img,
  svg {
    width: 100%;
    height: 100%;
  }
}

.feature_item {
  width: 45%;

  @include desc() {
    width: 30%;
  }

  @include mobile-min {
    width: 100%;
  }
}

.feature_item_title {
  word-break: break-word;
  @include font(500, start, 'Poppins', 1rem, 2rem);
  margin-bottom: 4px;
}

.feature_item_description {
  color: var(--grey-12);
  @include font(400, start, 'Poppins', .875rem, 1.3rem);
}


/////for cards
.card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  //  max-width: 375px;
  // min-height: 300px;
  cursor: pointer;
  margin-bottom: 4px;
  border-radius: 32px;
}

.card_header {
  padding: 0 1.875rem;
  @include font(600, start, 'Poppins', 1.5rem, 2rem);
}

.card_body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // border: 1px solid fuchsia;
  padding: 0 1.875rem !important;
  // padding-bottom: 18px;
}

.icon_wrapper {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-20);
  width: 24px;
  height: 24px;
  border-radius: 50px;
  margin-right: 10px;

  svg,
  img {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    margin-right: 0 !important;
    color: var(--white)
  }

}

.value_wrapper {
  max-width: 90%;
  color: white;
  padding: 8px;
  margin: 12px 0 17px;
  border-radius: 12px;
  @include font(700, start, Poppins, 2rem, 2rem);

  span {
    margin-left: 3px;
    @include font(500, start, Poppins, 1rem, 1.2rem);
  }

  &.value_text {
    color: black !important;
    @include font(600, start, Poppins, 1.125rem, 1.68rem);
  }
}

.fromTo_wrapper {
  width: auto;
  max-width: 65%;
  color: white;
  padding: 8px 14px;
  margin: 12px 0 17px;
  border-radius: 12px;
  @include font(700, start, Poppins, 1.125rem, 1.7);
  ;
}

.chart_wrapper {
  width: 100%;
  // border: 1px solid red;
  align-self: center;
  margin: 20px 0;
}

.gradient_chart_wrapper {
  width: 100%;
  border-radius: 200px;
  position: relative;
  height: 45px;
  background: linear-gradient(to right, #F96B6B 0%, #FFEA94 50%, #8FF777 100%);

  p {
    height: 60px;
    width: 2px;
    background-color: black;
    position: absolute;
    top: -7px;
  }
}

.description_wrapper {
  box-sizing: border-box;
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  /* Скрываем горизонтальную прокрутку */
  margin-bottom: 10px;
  @include font(400, start, Poppins, .8rem, 1.2rem);

  @include mobile {
    @include font(400, start, Poppins, 1rem, 1.4rem);
  }
}

.large_description {
  margin-bottom: 10px;
  margin-top: 20px;
  @include font(400, start, Poppins, 1.8rem, 2.6rem);

  @include mobile-min {
    @include font(400, start, Poppins, 2.5rem, 3.5rem);
  }
}

.ranks_with_img_wrapper {
  position: relative;
  width: 100%;
  // border: 1px solid red;
  margin-top: auto;
  display: flex;
  align-items: flex-end;
  margin-bottom: 18px;

  .ranks_block_wrapper {
    // width: 70%;
    //  border: 2px solid blue;
  }

  .ranks_image_wrapper {
    width: 30%;
    //  border: 2px solid green;
    overflow: visible;
    position: absolute;
    right: 0;

    img {
      display: block;
      width: 100%;
      margin-top: auto;
    }
  }
}

.ranks_items_wrapper {
  // border: 2px solid deeppink;
  overflow: auto;
  webkit-overflow-scrolling: touch;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.rank_item {
  background-color: var(--grey-21);
  border-radius: 20px;
  gap: 4px;
  padding: 4px 7px;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  p {
    color: var(--black);
    @include font(500, start, Poppins, .8rem, 2rem);
  }

}

.highlight_pool {
  padding: 0 13px;
  color: white;
  background: #285DAD;
  border-radius: 10px;
  @include font(700, start, Poppins, 1.8rem, 2rem);

  @include mobile-min {
    @include font(700, start, Poppins, 2.5rem, 2.75rem);
  }
}

.highlight_patio {
  padding: 0 13px;
  color: white;
  background: #B5866E;
  border-radius: 10px;
  @include font(700, start, Poppins, 1.8rem, 2rem);

  @include mobile-min {
    @include font(700, start, Poppins, 2.5rem, 2.75rem);
  }
}