@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './../../../../styles/helpers';

.levelsMenu {
  width: 25%;
  border: 1px solid var(--grey-7);
  border-radius: 24px;
  max-width: 325px;

  @include laptop-min {
    width: 100%;
    max-width: 100%;
  }

  .item {
    padding: 1.875rem 1.5rem 1.625rem 1.5rem;
    &:first-child {
      margin-top: -1px;
    }

    .title {
      color: var(--main-black, #161a21);
      @include font(400, start, 'Poppins', 1.25rem, 1.875rem);

      .counter {
        background: var(--grey-7);
        padding: 6px;
        min-width: 32px;
        @include font(600, center, 'Poppins', 1.125rem, 1.24rem);
      }
    }

    .description {
      display: none;
      margin-top: 8px;
      color: var(--grey-2);
      @include font(400, start, 'Poppins', 0.875rem, 1.3rem);
    }

    &:not(.current) + .item:not(.current)::before {
      position: absolute;
      top: 0;
      flex-shrink: 0;
      background: var(--grey-7);
      width: 90%;
      height: 1px;
      content: '';
    }

    &.current {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
      border-left: 4px solid var(--red-2);
      border-radius: 24px;
      background: var(--white);

      .title {
        color: var(--black-bg);
        font-weight: 600;

        .counter {
          background: var(--red-2);
        }
      }

      &.urgent {
        border-left: 4px solid var(--red-2);

        .counter {
          background: var(--red-2);
        }
      }

      &.moderate {
        border-left: 4px solid var(--orange-3);

        .counter {
          background: var(--orange-3);
        }
      }

      &.low {
        border-left: 4px solid var(--blue-1);

        .counter {
          background: var(--blue-1);
        }
      }

      .description {
        display: block;
      }

      &::after {
        display: none;
      }
    }
  }
}

//for mobile menu
.tabs {
  border: 1px solid var(--grey-7);

  .tab {
    width: 33%;
    cursor: pointer;
    @include font(400, center, 'Poppins', 1.125rem, 1.5rem);
    @include mobile-min {
      @include font(400, center, 'Poppins', .8rem, 1rem);
    }

    &.current {
      font-weight: 600;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      color: white;

      &.urgent {
        background: var(--red-2);
      }

      &.moderate {
        background: var(--orange-3);
      }

      &.low {
        background: var(--blue-1);
      }
    }
  }
}
