@import './../../styles/helpers';

.links_wrapper {
  margin-top: 2.5rem;

  a {
    color: var(--grey-13);
  }
}

.data_card {

  ul {
    @include font(400, start, 'Poppins', 0.875rem, 1.5rem);
    color: var(--grey-13);
    margin-left: 50px;
    margin-top: 20px;

    li {
      list-style-type: disc;
    }

  }
}

.profile_data {
  margin-bottom: 1.5rem;
  @include font(400, start, 'Poppins', 1rem, 1.7rem);

  label {
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }

}

.input {
  //border: 1px solid var(--grey-4);
  //border-radius: 10px;
  //width: 100%;
  padding: 0.75rem 2.5rem;
  color: var(--white-text);
  //margin-bottom: 2rem;
  @include font(400, start, 'Poppins', 0.875rem, 1.5rem);
}