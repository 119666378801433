@import './../../styles/helpers';

:global {
    .pac-container {
        margin-top: 1.25rem;
        box-shadow: 1px 1px 40px 0px rgba(0, 0, 0, 0.1);
        border: 1px solid var(--grey-1);
        border-radius: 1.5rem;
        background: var(--white);
        padding: 0.625rem;
        width: unset !important;
        min-width: 50px;

        &::after {
            background-image: none !important;
            height: 0;
        }

        .pac-item {
            border: none;
            border-radius: 20px;
            padding: 5px 10px;
        }
    }
}

.layout {
    main {
        position: relative;
        pointer-events: none;
        touch-action: none;
        user-select: none;
        overflow-y: hidden!important;
        border-radius: 0;
        background:
          //  url('../../assets/Revised_NavBackground.svg') center / cover,
        white;
          //  lightgray;
        padding-top: 1.25rem;
    }

}

.main_content {
    filter: blur(5px)!important;
}

.mobile_main {
    padding-bottom: 101px;
}

.mainTitle {
    color: var(--white-text);
    @include font(700, center, 'Poppins', 3.375rem, 4.725rem);

    @include mobile {
        @include font(700, center, 'Poppins', 2.7rem, 3.78rem);
    }
}

.addressInputBar {
    .input {
        color: var(--white-text);
        @include font(400, start, 'Poppins', 1.125rem, 1.5rem);

        //&::placeholder {
        //    color: var(--grey-2);
        //}
    }
}

.add_btn {
    font-size: 1.875rem;
}


.message_wrapper {
    z-index: 1000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 280px;
    padding: 24px 14px 17px;
    background-color: var(--blue-3);
    border-radius: 20px;

    .title {
        @include font(600, center, 'Poppins', 2rem, 2rem);
        color: var(--blue-4);
        @include mobile-min {
            @include font(600, center, 'Poppins', 2.6rem, 2.6rem);
        }
    }

    img {
        display: block;
        max-width: 125px;
        margin: 15px 0 5px;
    }

    .message {
        @include font(300, center, 'Poppins', .875rem, 1rem);
        color: var(--blue-5);
        @include mobile-min {
            @include font(300, center, 'Poppins', 1.2rem, 1.3rem);
        }
    }
}

