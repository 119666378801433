@import './../../styles/helpers';

.contents {
    max-width: 1440px;
    box-shadow: 0 -4px 24px 0 var(--box-shadow-1);
    border-radius: 40px 40px 0 0;
}

.title {
    color: var(--white-text);
    @include font(700, start, 'Poppins', 2.625rem, 3.675rem);
}

.subtitle {
    color: var(--grey-2);
    @include font(400, start, 'Poppins', 1.125rem, 1.5rem);
}

.footer {
    color: var(--grey-2);
    @include font(400, center, 'Poppins', 0.875rem, 1.5rem);
}