@import './../../styles/helpers';

.main_wrapper {
  width: 100%;
  height: 100%;
  background: url('../../assets/onboarding/complexity/complexity_bg_large.png') top / cover;

  @include mobile-min {
    background: url('../../assets/onboarding/complexity/complexity_bg_small.png') top / cover;
  }
}

.uploader {
  position: absolute;
  z-index: 2;
  width: 33%;
  top: 33%;
  left: 25px;
}