@import './../../styles/helpers';

.subscription {
    margin-top: 10px;
    @include font(400, start, 'Poppins', 1rem, 1.3rem);
}

.mobile_main {
    padding-top: 6.125rem;
    padding-bottom: 101px;
}

.card {
    max-width: 800px;
    border-radius: 32px!important;
}

.data_card {
   // margin-top: 5rem;
    margin-top: 2rem;
    @include mobile-min {
        margin-top: 3rem;
    }
}

.profileImage_large {
    border-radius: 100%;
    width: 8rem;
    height: 8rem;
}

.profileImage_small {
    border-radius: 100%;
    width: 5rem;
    height: 5rem;
}

.profile_data {
    margin-bottom: 1.5rem;
    @include font(400, start, 'Poppins', 1rem, 1.7rem);

    label {
        font-weight: 500;
        font-size: 1.2rem;
    }

    input {
        padding: .5rem 1rem;
        color: var(--grey-2);
    }
}

.input {
    border: 1px solid var(--grey-4);
    border-radius: 10px;
    width: 100%;
}
