@import './../../styles/helpers';

.title {
    color: var(--white-text);
    @include font(700, center, 'Poppins', 2.625rem, 3.675rem);
}

.desktop_main {
    padding-top: 50px;
}

.mobile_main {
    padding-top: 6.125rem;
    padding-bottom: 101px;
}

.main {
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
}

.send_bg {
    background: linear-gradient(#6a9176, #86a090)
}

.card_bg {
    background-color: rgba(75, 108, 84, 1) !important;
}

.formCard {
    max-width: 500px;

    .header :global {
        .contents {
            justify-content: center !important;
        }
    }

    .input {
        padding: 0.75rem 2.5rem;
        color: black;
        background-color: var((--white-bg));
        margin-bottom: 2rem;
        @include font(400, start, 'Poppins', 0.875rem, 1.5rem);
    }

}

.footer {
    color: var(--grey-2);
    @include font(400, center, 'Poppins', 0.875rem, 1.5rem);
}