@import '@/styles/helpers';

.small_card {
    &_body {
        h1 {
            font-size: 4rem;
            display: inline;
        }

        p {
            display: inline;
            margin-left: .3rem;
        }

        img {
            display: block;
            max-width: 120px;
            width: 60%;
            margin-left: auto;

            @include mobile-min {
                max-width: 40%;
                width: 40%;
            }
        }
    }
}

.status_bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 10px 0;
}

.step {
    width: 17%;
    height: 10px;
    background-color: rgb(179, 179, 179);
    border-radius: 10px;
}

.step:hover {
    cursor: pointer;
}