@import './../../styles/helpers';

.continueBtn {
  width: 100%;
  max-width: 200px;
  padding: 1.25rem 0 !important;
  font-size: 1rem;
}

.description {
  color: var(--grey-2);
  @include font(400, center, 'Poppins', 1.125rem, 1.57rem);
}

.fileUploader {
  border: 1px dashed var(--grey-4);
  border-radius: 24px;
  padding: 2.5rem 3.75rem;

  input {
    width: 100%;
  }

  h3 {
    color: var(--black-bg, #161a21);
    @include font(400, center, 'Poppins', 1.125rem, 1.57rem);
  }

  p {
    color: var(--grey-2);
    @include font(400, center, 'Poppins', 0.75rem, 1.05rem);
  }

  u {
    color: var(--black-bg);
    text-decoration: underline;
  }
}

.fileProgress {
  box-shadow: 0 2px 20px 0 rgba(24, 24, 26, 0.12);
  border-radius: 12px;
  background: var(--white-bg, #fff);
  @include font(400, center, 'Poppins', 0.75rem, 1.125rem);


  .name {
    max-width: 230px;
    overflow: hidden;
    color: var(--black-bg, #161a21);
    font-size: 12px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

  .size {
    color: var(--grey-2);
    font-size: 10px;
  }


  .progress {
    flex-wrap: nowrap;
    gap: 4px;
    width: 100%;

    .bar {
      border-radius: 4px;
      background-color: var(--grey-5);
      width: 100%;
      height: 8px;

      div {
        border-radius: 4px;
        background-color: var(--green-1);
        height: 100%;
      }
    }

    span {
      margin-bottom: -3px;
      min-width: 24px;
      color: var(--grey-2);
      font-size: 10px;
      text-align: right;
    }
  }
}
