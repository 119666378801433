@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './../../styles/helpers';

//for pdf container
.pdfContainer {
  margin-bottom: 5px;

  &_header {
    margin-bottom: -32px;
    border-radius: 32px 32px 0 0;
    background: var(--pink-1);
    padding: 20px 30px;
    min-height: 100px;
    color: var(--white-text);
    @include font(600, start, 'Poppins', 1.5rem, 2.1rem);
  }

  iframe {
    width: 100%;
    height: 85vh;
    border: 32px solid var(--white);
    border-width: 32px 26px;
    border-radius: 32px;
  }
}

////////////////////////////////
.price {
  color: var(--white);
  background: var(--grey-9);
  padding: 10px;
  border-radius: 15px;
  @include font(400, center, 'Poppins', 1rem, 1.4rem);

  &.urgent {
    background: var(--red-2);
  }

  &.moderate {
    background: var(--orange-3);
  }

  &.low {
    background: var(--blue-1);
  }

  &.general {
    background: var(--grey-9);
  }
}


//for BigDisplayContainer
.box {
  position: absolute;
  border-radius: 32px;
}

@keyframes smallBox1OriginalWidth {
  from {
    left: 100px;
    width: 100%;
  }

  to {
    left: 0;
    width: 100%;
  }
}

.box1 {
  width: 100% !important;
  animation: smallBox1OriginalWidth 0.5s ease forwards;
}

.smallBox1 {
  top: 30px;
  left: 12% !important;
  width: 75% !important;
}

.box1,
.smallBox1 {
  &.urgent {
    background-color: var(--pink-1);
  }

  &.moderate {
    background-color: var(--orange-bg);
  }

  &.low {
    background-color: var(--blue-bg);
  }

  &.general {
    background-color: var(--grey-9);
  }
}

@keyframes smallBox2OriginalWidth {
  from {
    width: 80%;
    top: 20px;
    left: 55px;
    // background-color: var(--grey-8);
    background-color: hsla(0, 0%, 100%, .699);
  }

  to {
    width: 100%;
    top: 80px;
    left: 10px;
    background-color: var(--white);
  }
}

.box2 {
  width: 97% !important;
  animation: smallBox2OriginalWidth 0.5s ease forwards;
}

.smallBox2 {
  // background-color: var(--grey-8);
  background-color: hsla(0, 0%, 100%, .699);
  top: 45px !important;
  left: 5% !important;
  width: 90% !important;
}

///////

.priceBtn {
  width: 160px;
  white-space: normal;
  margin-left: 1.5rem;
  position: absolute;
  bottom: 8px;
  z-index: 2;

  @include mobile-extra-min {
    max-width: 110px;
  }
}

.showBigCards {
  display: grid;
  grid-template-columns: calc(100% - 350px) 330px !important;

  @media (max-width: 1400px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  //////loader
  .customLoader {
    border: 5px solid var(--grey-10);
    border-top: 5px solid var(--grey-11);
    border-radius: 50%;
    width: 2.813rem;
    height: 2.813rem;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

}

.fixed_chat_button {
  //bottom: 2.5rem;
  //bottom: 9rem;
  right: 1.25rem;
  height: 5rem;
  width: 5rem;
  z-index: 1000;
}