@import '../../styles/helpers';

.layout {
  main {
    border-radius: 0;
    padding-top: 1.25rem;
  }

  .PricingTable {
    border: 1px solid green;
  }
}

.mobile_main {
  padding-top: 6.125rem;
  padding-bottom: 101px;
  //background:
  //       // url('../../assets/Revised_NavBackground.svg') center / cover,
  //        lightgray;
}

