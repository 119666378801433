@import './../../styles/helpers';

.room_menu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--white);
  z-index: 1002;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px 10px;
  width: 100%;
  border-radius: 0 0 22px 22px;
}

.upgrade_btn {
  padding: 2px 10px!important;
  @include font(400, center, 'Poppins', .8rem, 1rem);
}

.title {
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 10px;
  @include font(400, start, 'Poppins', 1.25rem, 1.875rem);
  @include mobile-min {
    @include font(400, start, 'Poppins', 1.66rem, 2.5rem);
  }
}

.notification_button {
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;

  .red_point {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    top: 0;
    right: 3.5px;
    background-color: var(--red-3);
  }

  @include mobile-extra-min {
    width: 32px;
    height: 32px;
  }
}