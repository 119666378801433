@import './../../styles/helpers';


.mobile_main {
  background:
          url('../../assets/Revised_NavBackground.svg') center / cover,
          lightgray;
  padding-bottom: 101px;
}

.main_image_block {
  margin: 0 auto 50px;
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }
}

.info_block {
  position: absolute;
  left: 50%;
  bottom: -20px;
  width: 50%;
  transform: translate(-50%, 0);

  .status {
    position: relative;
    width: 100%;
    background-color: var(--grey-22);
    color: var(--white);
    padding: 8px 0 20px 0;
    border-radius: 8px 8px 0 0;
    @include font(400, center, 'Poppins', .75rem, .875rem);
    @include mobile-min {
      @include font(400, center, 'Poppins', 1rem, 1.2rem);
    }

    &>p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0 3px 3px;
    }

    .address
    {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      width: 100%;
      background-color: var(--white);
      color: var(--black);
      padding: 8px;
      border-radius: 10px;
      @include font(400, center, 'Poppins', .75rem, 1rem);

      img {
        flex-shrink: 0;
        width: 10px!important;
        min-width: 10px!important;
        height: 10px!important;
      }

      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      @include mobile-min {
        @include font(400, center, 'Poppins', 1rem, 1.3rem);
      }
    }
  }
}

.section_links_wrapper {
  width: 100%;
  max-width: 700px;
  //border: 1px solid blue;
  margin: 0 auto;
  padding: 0 10px;
}

.link_wrapper {
  border: none!important;
  border-radius: 0!important;
  background: inherit!important;
  padding: 0!important;
  position: relative;
  width: 100%;
  cursor: pointer;

  &_title {
    position: absolute;
    left: 30px;
    top: 50%;
    width: 60%;
    transform: translate(0, -50%);

    @include font(500, start, 'Poppins', 1.5rem, 2rem);
    color: var(--black);

    @include mobile-min {
     // @include font(500, start, 'Poppins', 2rem, 2.5rem);
      @include font(500, start, 'Poppins', 1.8rem, 2.3rem);
    }
  }

  img {
    width: 100%;
    border-radius: 35px;
  }

}

.loader {
  position: absolute;
  //width: 3rem;
  //height: 3rem;
  left: 50%;
  top: 30%;
}