@import './../../../../styles/helpers';

.no_big_display_wrapper {
  @include laptop {
    max-width: 760px;
  }
}

.cards_wrapper {
  width: auto;

  @include laptop {
    width: 100%;
  }
}

.row_for_small_cards {
  flex-wrap: nowrap;

  @include laptop {
    flex-wrap: wrap;
  }
}


.small_card {
  &_body {
    h1 {
      font-size: 4rem;
      display: inline;
    }

    p {
      display: inline;
      margin-left: .3rem;
    }

    img {
      display: block;
      max-width: 120px;
      width: 60%;
      margin-left: auto;

      @include mobile-min {
        max-width: 40%;
        width: 40%;
      }
    }
  }
}

// .small_card {
//   &_body {
//     img {
//       display: block;
//       max-width: 120px;
//       width: 60%;
//       margin-left: auto;
//     }
//   }
// }

.chartCard {
  width: 100%;
  max-width: 760px !important;
  flex-grow: 1;

  .fit {
    @include font(700, start, 'Poppins', 2.625rem, 2.88rem);

    @media screen and (max-width: 500px) {
      font-size: 24px;
    }
  }

  .fitIndicator {
    color: var(--green-highlight);
  }

  .strongFit {
    color: var(--green-highlight);
  }

  .partialFit {
    color: var(--blue-1);
  }

  .weakFit {
    color: var(--red-1);
  }

  .chartContainer {
    margin-top: -4.5rem;
  }
}