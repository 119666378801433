@import './../../styles/helpers';

.mobile_main {
  padding-bottom: 101px;
}

.dashboard {
  background: url('../../assets/dashboard/dashboard_bg.png') center / cover;
}

.message_wrapper {
  max-width: 280px;
  padding: 24px 14px 17px;
  background-color: var(--blue-3);
  border-radius: 20px;

  .title {
    @include font(600, center, 'Poppins', 2rem, 2rem);
    color: var(--blue-4);
    @include mobile-min {
      @include font(600, center, 'Poppins', 2.6rem, 2.6rem);
    }
  }

  img {
    display: block;
    max-width: 125px;
    margin: 15px 0 5px;
  }

  .message {
    @include font(300, center, 'Poppins', .875rem, 1rem);
    color: var(--blue-5);
    @include mobile-min {
      @include font(300, center, 'Poppins', 1.2rem, 1.3rem);
    }
  }
}