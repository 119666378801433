@import './../../styles/helpers';

.continueBtn {
    max-width: 200px;
    padding: 1.25rem 0!important;
    font-size: 1rem;
}

.description {
    @include font(400, center, 'Poppins', 1.5rem, 2.1rem);

    @include laptop-min {
        @include font(400, center, 'Poppins', 1.125rem, 1.57rem);
    }
}
