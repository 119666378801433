@import '../../styles/helpers';

.card {
  position: relative;
  width: 100%;
  max-width: 350px;
 // min-height: 250px;
  height: 250px;
  aspect-ratio: 1 / .75;
  border-radius: 25px;
  overflow: hidden;

  @include laptop-min {
    max-width: 370px;
  }
}

.main_image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.common_btn {
  flex-shrink: 0;
  cursor: pointer;
  width: 45px;
  height: 45px;
  background-color: var(--grey-17);

  img {
    width: 25px!important;
    height: 25px!important;
  }
}

.close_btn {
  position: absolute;
  top: 10px;
  right: 1rem;
  background-color: rgba(38, 38, 38, .5) !important;
  &:hover {
    background-color: rgba(38, 38, 38, 1) !important;
  }
}

.link_btn {
  &:hover {
    transform: scale(1.1);
    margin-right: 3px;
  }
}

.info_block {
  position: absolute;
  left: 50%;
  bottom: 10px;
  width: calc(100% - 2rem);
  transform: translate(-50%, 0);
  background-color: rgba(38, 38, 38, .4) !important;
  padding: 3px 3px 3px 25px;
}

.addresses {
  width: 55%;
  color: var(--white);
  @include font(400, center, 'Poppins', 1.125rem, 2.625rem);

  .address2,
  .address1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .address2 {
    @include font(400, start, 'Poppins', 1rem, 1.4rem);
  }

  .address1 {
    @include font(400, start, 'Poppins', 12px, 16px);
  }
}

.bookMark {
  max-width: 60px;
  color: var(--white);
  background-color: var(--grey-17);

  p {
    @include font(400, start, 'Poppins', 12px, 16px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media screen and (max-width: 320px) {
  .addresses {
    width: 40%;
  }
}