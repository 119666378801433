@import './../../styles/helpers';

.main_wrapper {
  width: 100%;
  padding: 30px 0;
  border-right: 25px;
}

.close_btn {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  top: 1.563rem;
  right: 1.875em;
  background: url('../../assets/kanbanScreen/X.png');
  background-size:  cover;
}

.title {
  color: var(--white-text);
  @include font(700, center, 'Poppins', 2.625rem, 3.675rem);
}

.subtitle {
  color: var(--grey-2);
  @include font(400, start, 'Poppins', 1rem, 1.5rem);
}
