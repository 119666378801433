@import './../../styles/helpers';

.content_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.info_card {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
  padding: 40px 24px 15px 24px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

  .title {
    margin-bottom: 20px;
    @include font(600, center, 'Poppins', 1.5rem, 1.75rem);
    @include mobile-min {
      @include font(600, center, 'Poppins', 2rem, 2.33rem);
    }
  }

  .description {
    margin-bottom: 30px;
    @include font(300, center, 'Poppins', 0.875rem, 1rem);
    @include mobile-min {
      @include font(300, center, 'Poppins', 1.17rem, 1.14rem);
    }
  }

  button {
    @include font(500, center, 'Poppins', 1.6rem, 1.6rem);
    @include mobile-min {
      @include font(500, center, 'Poppins', 2rem, 2rem);
    }
  }

  .skip {
    text-decoration: underline;
    font-style: italic;
    cursor: pointer;
    @include font(300, center, 'Poppins', 0.75rem, 1rem);
    @include mobile-min {
      @include font(500, center, 'Poppins', 1rem, 1.3rem);
    }
  }
}

//for screens with height less than 730px
.info_card.small {
  padding: 15px 14px 10px 14px;

  .title {
    margin-bottom: 10px;
    @include font(600, center, 'Poppins', 1.3rem, 1.5rem);
    @include mobile-min {
      @include font(600, center, 'Poppins', 1.7rem, 1.7rem);
    }
  }

  .description {
    margin-bottom: 15px;
  }

  button {
    @include font(500, center, 'Poppins', 1rem, 1rem);
  }
}
