@import '../../styles/helpers';

.tabs {
    flex-wrap: nowrap;
    flex-basis: auto;
    gap: 3.75rem;
    color: var(--grey-6);
    text-overflow: ellipsis;
    @include font(400, center, 'Poppins', 1.125rem, 1.35rem);

    @include mobile {
        gap: 1.25rem;
    }

    .tab.current {
        position: relative;
        color: var(--black-bg);
        font-weight: 600;

        &:after {
            display: block;
            position: absolute;
            top: -10px;
            left: calc(50% - 3px);
            border-radius: 100%;
            background: var(--black-bg);
            width: 6px;
            height: 6px;
            content: '';
        }
    }
}

.upgrade_btn {
    padding: 2px 10px !important;
    @include font(400, center, 'Poppins', .8rem, 1rem);
}

.upgrade_btn:hover {
    background-color: gray;
    border-color: gray;
}

.profile {
    backdrop-filter: blur(5px);
    padding: 6px 12px 6px 6px;
    max-width: 262px;
}

.profileImage {
    border-radius: 100%;
    width: 3rem;
    height: 3rem;
}

.profileData {
    overflow: hidden;
    margin: 0 6px;

    .name {
        overflow: hidden;
        color: var(--white-text);
        text-overflow: ellipsis;
        white-space: nowrap;
        @include font(500, center, 'Poppins', 1rem, 1.5rem);
    }

    .status {
        color: var(--brown-1);
        font-style: italic;
        @include font(400, start, 'Share', 0.875rem, 1.05rem);
    }
}

.menu {
    .openButton {
        background: none;
        width: 2.25rem;
    }

    img {
        font-size: 2.25rem;
    }

    .items {
        position: absolute;
        right: 0;
        backdrop-filter: blur(5px);
        transition: opacity var(--animation-time) ease-in-out;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        border-radius: 12px;

        &.closed {
            opacity: 0;
        }

        &.open {
            opacity: 1;
        }

        .item {
            border-radius: 12px;

            &:hover {
                background: #f5f5f5;
            }
        }
    }
}