@import './../../styles/helpers';

.authentication_block {
  min-height: 100vh;
  max-width: 6000px;
}

.formCard {
  max-width: 500px;

  .header :global {
    .contents {
      justify-content: center !important;
    }
  }

  .input {
    padding: 0.75rem 2.5rem;
    color: var(--white-text);
    margin-bottom: 2rem;
    @include font(400, start, 'Poppins', 0.875rem, 1.5rem);
  }

}

.subtitle {
  margin: 6.25rem auto 3.125rem;
  @include font(500, center, 'Poppins', 3rem, 3rem);
  @include laptop {
    margin: 3.25rem auto 1.125rem;
  }
}

.intro_img {
  img {
    max-width: 100%;
    height: 90vh;
    max-height: 800px;
  }
}



